






























































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import AusterButton from "@/components/AusterButton.vue";

import appointmentModel from "@/models/appointment.model";

@Component({
  components: {
    AusterButton,
    ClipLoader,
  },
})
export default class Appointments extends Vue {
  currentPage = 1;
  lastPage = 0;
  history = false;
  appointmentsEvents: any[] = [];
  loading = false;

  showDetailsModal = false;
  detailsAppointmentEvent: any = null;

  mounted() {
    this.loadAppointments().catch(error => console.error(error));
  }

  async changePage(pageDelta = 1) {
    if (this.currentPage + pageDelta >= 1 && this.currentPage + pageDelta <= this.lastPage) {
      this.currentPage = this.currentPage + pageDelta;
      await this.loadAppointments();
    }
  }

  async loadAppointments() {
    this.loading = true;
    try {
      ({ lastPage: this.lastPage, results: this.appointmentsEvents } = await appointmentModel.getAppointmentsLatestEvents(this.currentPage, this.history));
      if (this.appointmentsEvents) {

        //console.log("logD", this.appointmentsEvents);
        this.appointmentsEvents.forEach(ev => {
          ev.appointmentStartDateTime = ev.appointmentStartDateTime.replace('Z', '');
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  }

  getEventStatus(appointmentEvent: any) {
    if (appointmentEvent.appointment?.status === "COMPLETED") {
      return { class: "text-green-300", text: "Consulta realizada", detailsText: this.userRole === "PSYCHOLOGIST" ? "(Prontuário)" : "" };
    }

    //comentado em 30/09/2024 
    // if (appointmentEvent.appointment?.status === "FAILED") {
    //   return { class: "text-red-300", text: "Consulta não realizada", detailsText: "(status)" };
    // }

    switch (appointmentEvent.kind) {
      case "SCHEDULED":
        return { class: "text-yellow-300", text: "Voucher pendente", detailsText: this.userRole === "PATIENT" ? "(Realizar o envio do voucher)" : "" };
      case "RECEIPT_SENT":
        return { class: "text-yellow-300", text: "Aguardando confirmação", detailsText: this.userRole === "PSYCHOLOGIST" ? "(Verificar)" : "" };
      case "VOUCHER_APPLIED":
      case "CONFIRMED":
        return { class: "text-green-300", text: "Consulta confirmada", detailsText: "(Saiba mais)" };
      case "REFUSED":
        return { class: "text-red-300", text: "Comprovante recusado", detailsText: "(Saiba mais)" };
      case "CANCELLED":
        return { class: "text-red-300", text: "Consulta cancelada", detailsText: "(Saiba mais)" };      
    }
    return { class: "text-red-300", text: "-", detailsText: "-" };
  }

  openDetails(appointmentEvent: any) {
    switch (appointmentEvent.kind) {
      case "REFUSED":
      case "CANCELLED":
        this.detailsAppointmentEvent = appointmentEvent;
        this.showDetailsModal = true;
        break;
      case "RECEIPT_SENT":
        this.$router.push("/dash/solicitacoes");
        break;
      default:
        if (appointmentEvent.appointment?.id) {
          this.$router.push(`/dash/consulta/${appointmentEvent.appointment.id}`)
        } else {
          console.error("Appointment has no id");
        }
    }
  }

  @Watch("history")
  async historyUpdated(history, previousHistory) {
    if (history !== previousHistory) {
      await this.loadAppointments();
    }
  }

  get userRole() {
    return this.$store.state.activeUser?.role;
  }
}
